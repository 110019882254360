import request from '@/utils/request'

// 查询账单改变历史
export function findAgentBillHistories(params) {
  return request({
    url: `/agent_bill_changed_histories`,
    method: 'get',
    params: params
  })
}
