<template>
  <div>
    <a-modal
      width="600px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="历史"
    >
      <template slot="footer">
        <a-button
          type="primary"
          @click="closeModal"
        >
          关闭
        </a-button>
      </template>

      <a-timeline>
        <a-timeline-item v-for="history in data" :key="history.id">
          <p>
            <a-space>
              <span>{{ history.created_at }}</span>
              <span>{{ history.changed_type }}</span>
              <span>({{ history.operator_full_name }})</span>
            </a-space>
          </p>
          <p>{{ history.content }}</p>
        </a-timeline-item>
      </a-timeline>

    </a-modal>
  </div>
</template>

<script>
import { findAgentBillHistories } from '@/api/agent_bill_changed_history'

export default {
  name: 'AgentBillChangedHistoryList',

  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      query: {
        page: 1,
        per_page: 100000 // TODO 分页
      }
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAgentBillHistories(Object.assign({ agent_bill_id: this.id },
        this.query)).then((res) => {
        this.data = res.data
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

